<template>
    <section class="main-container is-column">
        <SectionTitleComponent
            :title="title"
            :description="message"
        />
    </section>
</template>
<script>
import SectionTitleComponent from '@/components/SectionTitleComponent.vue'
export default {
    name: 'ApiPage',
    components: {
        SectionTitleComponent
    },
    props: {
        title: {
            type: String,
            default: 'API'
        },
        message: {
            type: String,
            default: 'The API you are trying to access is currently in development.'
        }
    }
}
</script>
