// src/router.js
import {
    createRouter,
    createWebHistory
} from 'vue-router'

// Import all your page components
import ApiPage from '@/views/ApiPage.vue'
import NotFoundPage from '@/views/NotFoundPage.vue'

const routes = [{
        path: '/',
        name: 'Home',
        component: ApiPage,
    }, {
        alias: ['/home', '/start', '/index', '/api'],
        redirect: {
            name: 'Home'
        }
    }, {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: NotFoundPage
    }, {
        alias: ['/404', '/error'],
        redirect: {
            name: 'NotFound'
        }
    }
]


const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router